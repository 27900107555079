import React, { useEffect, useState } from 'react'

const NavbarDasktop = (props) => {

  const Ismobile = props.isMobile

  const [NavIndex, setNavIndex] = useState(props.navht);

  useEffect(() => {
    setNavIndex(props.navht)
  }, [props.navht])
  

  useEffect(() => {

    const timer = setTimeout(() => {
      if (NavIndex!==0) {
        setNavIndex(0)
      }
    }, 5000);

    return () => clearTimeout(timer);

    
  }, [NavIndex])


  

    return (
      <>
      <div style={{right:0,left:0,top:0,height:72,backgroundColor:'#343a40',display:'flex',alignItems:'center',justifyContent:'space-between',position:'fixed',overflow:'hidden'}}>
          <div style={{display:'flex',alignItems:'center'}}>
        {Ismobile&&<i onClick={()=>props.setx('contact')} style={{color:'#f8f9fa',marginLeft:20,fontSize:20}} class="fa-solid fa-bars-staggered"></i>}

          <div style={{textAlign:'center'}}>
          <h1 style={{fontSize:20,margin:'0px 30px',color:'#f8f9fa',fontWeight:'bold'}}>Duniyape</h1>
          <h1 style={{fontSize:14,margin:'0px 30px',color:'#f8f9fa',fontWeight:500}}>Technologies</h1>
        </div>
        
  
        {!Ismobile&&<div style={{display:'flex'}}>
          <span onClick={()=>setNavIndex(NavIndex!==1?1:0)} style={{padding:'0px 20px',color:'rgb(158, 159, 163)',fontSize:16}}>What we do</span>
          <span onClick={()=>setNavIndex(NavIndex!==2?2:0)} style={{padding:'0px 20px',color:'rgb(158, 159, 163)',fontSize:16}}>Who we are</span>
          <span onClick={()=>setNavIndex(NavIndex!==3?3:0)} style={{padding:'0px 20px',color:'rgb(158, 159, 163)',fontSize:16}}>Insights</span>
          <span onClick={()=>setNavIndex(NavIndex!==4?4:0)} style={{padding:'0px 20px',color:'rgb(158, 159, 163)',fontSize:16}}>Careers</span>
          <span onClick={()=>setNavIndex(NavIndex!==5?5:0)} style={{padding:'0px 20px',color:'rgb(158, 159, 163)',fontSize:16}}>Newsroom</span>
          {/* <span onClick={()=>setNavIndex(6)} style={{padding:'0px 20px',color:'rgb(158, 159, 163)',fontSize:16}}>Investors</span> */}
        </div>}
          </div>
  
  
          <div>
          <div style={{display:'flex',margin:'0px 20px',alignItems:'center'}}>
             {!Ismobile &&<><a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@duniyatech.com"><div style={{display:'flex',alignItems:'center',margin:'0px 10px'}}>
             <i style={{padding:'0px 10px',color:'#f8f9fa',fontSize:16}} class="fa-solid fa-phone"></i><span style={{color:'#f8f9fa'}}>Contact Us</span>
              </div></a>
              <div style={{display:'flex',alignItems:'center',margin:'0px 10px'}} onClick={()=>props.setx('service')}>
              <i style={{padding:'0px 10px',color:'#f8f9fa',fontSize:16}} class="fa-solid fa-mug-hot"></i><span style={{color:'#f8f9fa'}}>Services</span>
              </div></>}
              <img src='images/logo2.png' style={{width:120,marginLeft:Ismobile?0:20}} alt='img'/>
            {Ismobile&&<a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@duniyatech.com"><i style={{color:'#f8f9fa',margin:'0px 20px',fontSize:20}} class="fa-solid fa-phone"></i></a>}
          </div>
          </div>
      
      </div>


        {NavIndex!==0&&<div style={{width:Ismobile?'100%':'100vw',top:72,padding:'5% 0px',backgroundColor:'#343a40',display:'flex',alignItems:'center',justifyContent:'center',position:'fixed',overflow:'hidden'}}>

          {NavIndex===2&&<div style={{width:'50%'}}>
          <h3 style={{color:'white',fontSize:28,fontWeight:400}}>Duniyape Technologies: Provides software solutions for cooperative and agricultural sector</h3>
          <p style={{color:'white'}}>FinCoop ERP Solution : Designed for entire cooperative sector, Provides online solutions for primary to apex level institutions, Common accounting system, Integrates with CBS banking platform</p>
          <p style={{color:'white'}}>Impact : Serving 3000 cooperative bodies in 6 states (Punjab, Himachal Pradesh, Haryana, Rajasthan, Madhya Pradesh, Uttarakhand, and Bihar) , Enabling rural areas to provide better services and grow their businesses</p>
          </div>}

          {NavIndex===1&&<div style={{width:'50%'}}>
          <h3 style={{color:'white',fontSize:28,fontWeight:400}}>Duniyape Technologies</h3>
          <p style={{color:'white'}}>Helping farmers by consulting and implementing the latest technology and providing excellent services in the agricultural and cooperative sector across the globe. To achieve our objectives through reliable and environment-friendly means with utmost sincerity and honesty towards our consumers, farmers and colleagues.</p>
          </div>}

          {NavIndex===3&&<div style={{width:'50%'}}>
          <h3 style={{color:'white',fontSize:28,fontWeight:400}}>Extraordinary expertise leads to remarkable results.</h3>
          <p style={{color:'white'}}>We share news, insights, analysis and research – tailored to your unique interests – to help you deepen your knowledge and impact.</p>
          </div>}

          {NavIndex===4&&<div style={{width:'50%'}}>
          <h3 style={{color:'white',fontSize:28,fontWeight:400}}>Careers at Duniyape Technologies</h3>
          <p style={{color:'white'}}>We're looking for dynamic, innovative, and talented professionals to join our team. We offer the best compensation package and growth opportunity</p>
          <p style={{color:'white'}}>Our Hiring Approach : We value personal values in addition to talent and skills. We encourage people from villages and small towns to apply. We provide valuable training to our fresh recruits</p>
          <p style={{color:'white'}}>Our Advantage : Our people are our biggest advantage. We invest in our employees' success with training and incentive programs. We encourage employees to take on new challenges and grow their careers.</p>
          <p style={{color:'white'}}>Equal Employment Opportunity : We adhere to all laws governing employer-employee relationships. We prohibit discrimination based on various factors such as color, race, religion, etc.</p>
          </div>}

          {NavIndex===5&&<div style={{width:'50%'}}>
          <h3 style={{color:'white',fontSize:28,fontWeight:400}}>Find the latest news about Duniyape Technologies in our Newsroom</h3>
          <p style={{color:'white'}}>Get access to a catalog of the latest news stories from across Duniyape Technologies. Discover our press releases, reports, and company announcements.</p>
          </div>}

          {/* {NavIndex===6&&<div style={{width:'50%'}}>
          <h3 style={{color:'white',fontSize:28,fontWeight:400}}>Duniyape Technologies is here to make a difference through technology.</h3>
          <p style={{color:'white'}}>Leading the way in innovation for over 1 years, we build greater futures for businesses across multiple industries and 1 countries</p>
          </div>} */}
        
        </div>}

</>


    )
  }


export default NavbarDasktop
