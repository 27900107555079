import React from 'react'
import './Btn.css'
const Header = (props) => {

  const Ismobile = props.isMobile

  return (
    <div style={{width:Ismobile?'100%':'100vw',paddingTop:72,overflow:'hidden'}}>

      {Ismobile? <video autoPlay loop muted playsInline style={{height:'100vh'}}>
        <source src='images/header-video.mp4' type='video/mp4'/>
      </video>:

<video autoPlay loop muted playsInline style={{width:'100vw'}}>
<source src='images/header-video.mp4' type='video/mp4'/>
</video>
} 



<div style={{position:'absolute',top:72,width:'100vw',display:'flex',alignItems:'center',height:'80vh'}}>


      <div style={{marginLeft:Ismobile?30:200}} data-aos="fade-right">
      <h1 style={{fontSize:Ismobile?40:70,color:'white',textShadow:'2px 1px black',margin:0}}>Duniyape Technologies</h1>
      <h2 style={{fontSize:Ismobile?18:24,color:'white',fontWeight:400,margin:'10px 0px 20px 0px'}}>Software Consulting and Development</h2>
      <div>
  
      </div>
      
      </div>
      
      </div>
      



    </div>
  )
}

export default Header
