import React from 'react'
import './Projectview.css'

const ProjectView = (props) => {

  const Ismobile = props.isMobile



    const arry = [
        {
            img : 'https://img.freepik.com/free-photo/accountant-calculating-profit-with-financial-analysis-graphs_74855-4937.jpg?t=st=1718508880~exp=1718512480~hmac=5e04f49ad82a6845e640753275fef55b3c81e7e34ea34c6ccc7aa4e0632507df&w=2000',
            name : 'Banking Software'
        },{
            img : 'https://img.freepik.com/free-photo/trading-finances-young-man-resting-couch-while-checking-stock-market-laptop_662251-2187.jpg?t=st=1718508914~exp=1718512514~hmac=b3268a7592a3420b0362bb413cdc4e0b6a0948c55ac1c015814985c6febbc57a&w=2000',
            name : 'Trading Algorithm'
        },{
            img : 'https://img.freepik.com/free-photo/representations-user-experience-interface-design_23-2150038903.jpg?t=st=1718508970~exp=1718512570~hmac=9d2496377f1d6380a258294eb5e9574e4ec3669e9ea5145e882ba14c7106f3bd&w=1800',
            name : 'Mobile Application'
        },{
            img : 'https://img.freepik.com/free-photo/ui-ux-representations-with-laptop_23-2150201871.jpg?t=st=1718509055~exp=1718512655~hmac=522a425cd3fbdab3a90a49fb41a698be513540f1739b2e565f1cc6a88d366865&w=2000',
            name : 'e-commerce Services'
        }
    ]


  return (
    <div>
        <div style={{height:2,background:'#BEBEBE',margin:'70px 20%'}}></div>
        <h1 style={{fontSize:14,marginLeft:'5%'}} data-aos="fade-right">
            SERVICES
        </h1>
        <h2 data-aos="fade-right" style={{fontWeight:400,marginLeft:'5%',fontSize:Ismobile?18:24}}>Transform your business with advanced technologies</h2>
      <div style={{display:Ismobile?'contents':'flex',justifyContent:'center'}}>
            {
            arry.map((item,index)=>(
                <div data-aos="fade-up" style={{overflow:'hidden',width:Ismobile?'98vw':300,height:Ismobile?400:300,margin:Ismobile?'5px 1vw':15}}>
                    <h3 style={{color:'black',position:'absolute',background:'white',padding:8,fontSize:14}}>{item.name}</h3>
                    <img className='zoom' style={{height:'100%',width:'100%',objectFit:'cover'}} src={item.img} alt='img'/>
                </div>
            ))
            }
      </div>
    </div>
  )
}

export default ProjectView
