import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
const Footer = (props) => {

  return (
   <footer class="footer" style={{marginTop:70}}>
   <div class="container">
      <div class="row">
         <div class="footer-col" data-aos="flip-left">
            <h4>company</h4>
            <ul>
               <li onClick={()=>props.sety('about')}><a>about</a></li>
               <li onClick={()=>props.sety('service')}><a>our services</a></li>
               <li><Link to='/privacy-policy'><a>privacy policy</a></Link></li>
               {/* <li onClick={()=>props.sety('')}><a>affiliate program</a></li> */}
            </ul>
         </div>
         <div class="footer-col" data-aos="flip-left">
            <h4>Explore</h4>
            <ul>
               <li onClick={()=>props.setx(1)}><a >What we do</a></li>
               <li onClick={()=>props.setx(2)}><a >Who we are</a></li>
               <li onClick={()=>props.setx(3)}><a >Insights</a></li>
               <li onClick={()=>props.setx(4)}><a >Careers</a></li>
               <li onClick={()=>props.setx(5)}><a >Newsroom</a></li>
            </ul>
         </div>
         <div class="footer-col" data-aos="flip-left">
            <h4>Contact Us</h4>
            <ul>
               <li><a> Address : Shop No-28, ModelTown, Phase-3, <br/>Bathinda-151001</a></li>
               <li><a onClick={()=>window.location.href = 'https://mail.google.com/mail/?view=cm&fs=1&to=support@duniyatech.com'}>support@duniyape.in</a></li>
               <li><a >+91 6265 578 975</a></li>
            </ul>
         </div>
         <div class="footer-col" data-aos="flip-left">
            <h4>follow us</h4>
            <div class="social-links">
               <a ><i class="fab fa-facebook-f"></i></a>
               <a ><i class="fab fa-twitter"></i></a>
               <a ><i class="fab fa-instagram"></i></a>
               <a ><i class="fab fa-linkedin-in"></i></a>
            </div>
         </div>
      </div>
   </div>
</footer>
  )
}

export default Footer
