import logo from './logo.svg';
import './App.css';
import NavbarDasktop from './components/navbar/NavbarDasktop';
import Header from './components/header/Header';
import Services from './components/services/Services';
import Middleimage from './components/middleimage/Middleimage';
import { useEffect, useState } from 'react';
import ProjectView from './components/projectview/ProjectView';
import Footer from './components/footer/Footer';
import InquiryForm from './components/inquiry/InquiryForm';



function App() {


  


  return (
    <Root/>
  );
}

export default App;


const Root=()=>{


  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [navht, setNavht] = useState(0)
  const navhandle = (data) => {
    setNavht(data);
    console.log(data)
};


const handleDataFromChild = (data) => {
  document.getElementById(data).scrollIntoView({ behavior: "smooth" });
};




  return (
<div>

    
     

<div>
<Header isMobile={isMobile}/>
</div>

<div>
  <Middleimage isMobile={isMobile}/>
</div>

<div id='about'>
  <Services/>
</div>

<div id='service'>
  <ProjectView isMobile={isMobile}/>
</div>

<div>
  <InquiryForm isMobile={isMobile}/>
</div>

<div id='contact'>
  <Footer isMobile={isMobile} setx={navhandle} sety={handleDataFromChild}/>
</div>

<div>
<NavbarDasktop isMobile={isMobile} setx={handleDataFromChild} navht={navht}/>
</div>

</div>
  )
}
