import React from 'react'

const Middleimage = (props) => {

  const Ismobile = props.isMobile



  return (

   
    <div style={{width:Ismobile?'100%':'100vw',height:'100vh',display:Ismobile?'contents':'flex'}}>


      <div style={{width:Ismobile?'100%':'50vw',overflow:'hidden'}} data-aos="fade-right">
        <img style={{width:'100%'}} src='https://images.pexels.com/photos/12903149/pexels-photo-12903149.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='img'/>
      </div>

      <div style={{
        width:Ismobile?'100%':'49.7vw',
        marginLeft:Ismobile?0:'0.3vw',
        overflow:'hidden'}} data-aos="fade-left">
        <img style={{width:'100%'}} src='https://images.pexels.com/photos/12903182/pexels-photo-12903182.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='img'/>


      </div>

    </div>


  )
}

export default Middleimage
