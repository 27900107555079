import React from 'react'
import './Inquiry.css'

function InquiryForm(props) {

  const Ismobile = props.isMobile
    const [result, setResult] = React.useState("Submit");

    const onSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "9b83b1b8-bd43-4cb9-82c7-577f0123ea5a");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    };



  return (

    <>
<form onSubmit={onSubmit}>
<div style={{height:2,background:'#BEBEBE',margin:'70px 20%'}}></div>
<h1 style={{fontSize:14,marginLeft:'5%'}} data-aos="fade-right">
            INQUIRY FORM
        </h1>
        <h2 data-aos="fade-right" style={{fontWeight:400,marginLeft:'5%',fontSize:Ismobile?18:24}}>Transform your business with advanced technologies</h2>

    
    
    

    <div style={{
        display: 'flex',
        alignItems:'center',
        justifyContent:'center',
        width:'100vw'
    }}>


    
    <div style={{display:'flex',flexWrap:'wrap',width:Ismobile?'90%':'70%',margin:'60px 0px',justifyContent:'end',gap:30}}>

<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> Contact Person Name<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="text" name="name" placeholder='Contact Person Name' required/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> Email ID<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="email" name="email" placeholder='Email ID' required/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> Mobile No<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="number" name="number" placeholder='Mobile No' required/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> Name of Organization<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="text" name="organization" placeholder='Name of Organization'/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> Type of organization<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="text" name="organizationtype" placeholder='Type of organization'/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> Landline No<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="number" name="landline" placeholder='Landline No'/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> Address<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="text" name="address" placeholder='Address'/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> District<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="text" name="district" placeholder='District'/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> State<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="text" name="state" placeholder='State'/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> Pin Code<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="number" name="pincode" placeholder='Pin Code'/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> Required Solution<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="text" name="requiredsolution" placeholder='Required Solution'/></label>
<label data-aos="flip-left" style={{width:450,textAlign:Ismobile?'start':'end'}}> Special Requirements<input style={{marginLeft:15,marginTop:Ismobile?10:0,width:Ismobile?'auto':250}} type="text" name="specialrequirements" placeholder='Special Requirements'/></label>

    </div>
    </div>

<div style={{textAlign:'center'}}>

    <button type="submit" data-aos="flip-left" class="button2">{result}</button>
</div>


</form>
    </>
  )
}

export default InquiryForm
